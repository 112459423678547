import { green } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: '#888',
            borderColor: '#888',
            borderWidth: 3,
          },
          backgroundColor: '#ddd',
          borderRadius: 16,
          borderWidth: 3,
          color: 'black',
          fontWeight: 'bold',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
  palette: {
    background: {
      default: '#111',
    },
    primary: {
      main: '#ddd',
    },
    secondary: {
      main: green[500],
    },
    text: {
      primary: '#ddd',
    },
  },
  typography: {
    fontSize: 22,
  },
});
