import { useReducer, useState } from 'react';

import { useStopwatch } from 'react-timer-hook';

import { Button } from '../componentes/Button';
import { Countdown } from '../componentes/Countdown';
import { Digits } from '../componentes/Digits';
import { TextBox } from '../componentes/TextBox';
import { calcTotalSeconds } from '../utils/time';
import { useSounds } from '../utils/use-sounds';
import { PageContainer } from './PageContainer';

const BEEP_OFFSET = 5;

type State = {
  isSetup: boolean;
  isCountdown: boolean;
  isStart: boolean;
};

enum ActionType {
  SET_COUNTDOWN,
  SET_SETUP,
  SET_START,
}

const initialState = {
  isCountdown: false,
  isSetup: true,
  isStart: false,
};

function reducer(state: State, action: ActionType) {
  switch (action) {
    case ActionType.SET_COUNTDOWN:
      return {
        isCountdown: true,
        isSetup: false,
        isStart: false,
      };
    case ActionType.SET_SETUP:
      return {
        isCountdown: false,
        isSetup: true,
        isStart: false,
      };
    case ActionType.SET_START: {
      return {
        isCountdown: false,
        isSetup: false,
        isStart: true,
      };
    }
    default:
      return state;
  }
}

const TimePage: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { playMid, playFinal } = useSounds();
  const [startMinutes, setStartMinutes] = useState(5);
  const [startSeconds, setStartSeconds] = useState(0);
  const { seconds, hours, minutes, isRunning, pause, start } = useStopwatch({});

  const startCoundown = () => {
    dispatch(ActionType.SET_COUNTDOWN);
  };

  const startClock = () => {
    start();
    dispatch(ActionType.SET_START);
  };

  if (state.isStart && isRunning) {
    const totalSeconds = calcTotalSeconds(startSeconds, startMinutes);
    const secondsRunning = calcTotalSeconds(seconds, minutes, hours);

    if (secondsRunning === 0) {
      playFinal();
    } else if (totalSeconds <= secondsRunning) {
      playFinal();
      dispatch(ActionType.SET_SETUP);
    } else if (totalSeconds - BEEP_OFFSET <= secondsRunning) {
      playMid();
    }
  }

  return (
    <PageContainer>
      {state.isSetup && (
        <>
          <TextBox
            endLabel={'Minutes'}
            onChange={(e) => {
              const newMinutes = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );
              setStartMinutes(newMinutes);
            }}
            value={startMinutes}
          />

          <TextBox
            endLabel={'Seconds'}
            onChange={(e) => {
              const newSeconds = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );
              setStartSeconds(newSeconds < 60 ? newSeconds : 59);
            }}
            value={startSeconds}
          />

          <Button onClick={startCoundown}>Start</Button>
        </>
      )}

      {state.isCountdown && <Countdown onExpire={startClock} />}

      {state.isStart && (
        <>
          <Digits hours={hours} minutes={minutes} seconds={seconds} />

          <Button onClick={isRunning ? pause : start}>
            {isRunning ? 'Pause' : 'Resume'}
          </Button>
        </>
      )}
    </PageContainer>
  );
};

export default TimePage;
