import { PropsWithChildren } from 'react';

import { AppBar, Box, Grid, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useLocation } from 'react-router-dom';

import logo from '../assets/images/logo.jpg';
import { ButtonHome } from '../componentes/ButtonHome';
import { Footer } from '../componentes/Footer';
import { ROUTE_PATHS } from '../routing/route-paths';

const StyledBox = styled(Box)(() => ({
  backgroundColor: '#111',
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
}));

function getTitle(pathname: string) {
  switch (pathname) {
    case ROUTE_PATHS.AMRAP:
      return 'AMRAP';
    case ROUTE_PATHS.EMOM:
      return 'EMOM';
    case ROUTE_PATHS.TABATA:
      return 'TABATA';
    case ROUTE_PATHS.CLOCK:
      return 'CLOCK';
    case ROUTE_PATHS.TIME:
      return 'FOR TIME';
  }
  return '';
}

interface IProps {
  children: React.ReactNode;
}

export const PageContainer: React.FC<PropsWithChildren<IProps>> = ({
  children,
}: IProps) => {
  const location = useLocation();

  const title = getTitle(location.pathname);

  return (
    <StyledBox>
      <AppBar position="fixed" color="transparent">
        <Toolbar variant="regular" sx={{ paddingRight: '0 !important' }}>
          {title && <ButtonHome />}
          <Typography variant="body2" color="inherit" component="div">
            {title}
          </Typography>

          <Box
            component="img"
            alt="LOGO"
            src={logo}
            sx={{
              borderBottomLeftRadius: 16,
              maxWidth: 120,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
        </Toolbar>
      </AppBar>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%', width: '100%' }}
      >
        <Grid
          xs={10}
          sm={8}
          md={6}
          lg={4}
          item
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ flexDirection: 'column' }}
        >
          {children}
        </Grid>
      </Grid>
      <Footer />
    </StyledBox>
  );
};
