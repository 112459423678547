import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';

export const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
}));

type IProps = TextFieldProps & {
  endLabel?: string;
};

export const TextBox: React.FC<IProps> = (props: IProps) => {
  const { endLabel, ...restProps } = props;
  return (
    <TextField
      color="primary"
      fullWidth
      focused
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <StyledLabel>{endLabel}</StyledLabel>
          </InputAdornment>
        ),
      }}
      {...restProps}
      style={{ marginBottom: 10, marginTop: 10 }}
    />
  );
};
