/* eslint-disable sort-keys */
import { useReducer, useState } from 'react';

import { useTimer } from 'react-timer-hook';

import { Button } from '../componentes/Button';
import { Countdown } from '../componentes/Countdown';
import { Digits } from '../componentes/Digits';
import { Rounds } from '../componentes/Rounds';
import { TextBox } from '../componentes/TextBox';
import { calcTotalSeconds } from '../utils/time';
import { useSounds } from '../utils/use-sounds';
import { PageContainer } from './PageContainer';

const BEEP_OFFSET = 5;

type State = {
  isSetup: boolean;
  isCountdown: boolean;
  isStart: boolean;
};

enum ActionType {
  SET_COUNTDOWN,
  SET_SETUP,
  SET_START,
}

const initialState = {
  isCountdown: false,
  isSetup: true,
  isStart: false,
};

function reducer(state: State, action: ActionType) {
  switch (action) {
    case ActionType.SET_COUNTDOWN:
      return {
        isCountdown: true,
        isSetup: false,
        isStart: false,
      };
    case ActionType.SET_SETUP:
      return {
        isCountdown: false,
        isSetup: true,
        isStart: false,
      };
    case ActionType.SET_START: {
      return {
        isCountdown: false,
        isSetup: false,
        isStart: true,
      };
    }
    default:
      return state;
  }
}

const TabataPage: React.FC = () => {
  const { playMid, playFinal } = useSounds();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [intervalWork, setIntervalWork] = useState(20);
  const [intervalRest, setIntervalRest] = useState(10);
  const [intervalTotalSeconds, setIntervalTotalSeconds] = useState(0);
  const [rounds, setRounds] = useState(10);
  const { seconds, hours, minutes, isRunning, pause, resume, restart } =
    useTimer({
      autoStart: false,
      expiryTimestamp: new Date(),
      onExpire: () => dispatch(ActionType.SET_SETUP),
    });

  const startCoundown = () => {
    dispatch(ActionType.SET_COUNTDOWN);
  };

  const startClock = () => {
    const time = new Date();
    const totalSeconds = calcTotalSeconds(
      intervalRest * rounds + intervalWork * rounds
    );
    time.setSeconds(time.getSeconds() + totalSeconds);
    restart(time);
    setIntervalTotalSeconds(totalSeconds / rounds);
    dispatch(ActionType.SET_START);
  };

  let currentRound = 0,
    currentMinutes = 0,
    currentSeconds = 0;

  if (state.isStart) {
    const secondsLeft = calcTotalSeconds(seconds, minutes, hours) - 1;
    const secondsLeftInRound =
      Math.ceil(secondsLeft % intervalTotalSeconds) + 1;

    const isInRest = secondsLeftInRound <= intervalRest;
    const secondsLeftInRoundStage = isInRest
      ? secondsLeftInRound
      : secondsLeftInRound - intervalRest;

    currentSeconds = secondsLeftInRoundStage % 60;
    currentMinutes = Math.floor(secondsLeftInRoundStage / 60);
    currentRound = rounds - Math.floor(secondsLeft / intervalTotalSeconds);

    if (isRunning) {
      if (
        seconds === 0 ||
        (isInRest && secondsLeftInRound === intervalRest) ||
        (!isInRest && secondsLeftInRound === intervalTotalSeconds)
      ) {
        playFinal();
      } else if (secondsLeftInRoundStage <= BEEP_OFFSET) {
        playMid();
      }
    }
  }

  return (
    <PageContainer>
      {state.isSetup && (
        <>
          <TextBox
            endLabel="Work"
            onChange={(e) => {
              const newMinutes = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );
              setIntervalWork(newMinutes);
            }}
            value={intervalWork}
          />

          <TextBox
            endLabel="Rest"
            value={intervalRest}
            onChange={(e) => {
              const newSeconds = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );

              setIntervalRest(newSeconds < 60 ? newSeconds : 59);
            }}
            onBlur={(e) => {
              if (!e.target.value.replace(/[^0-9]/g, '')) {
                setIntervalRest(0);
              }
            }}
          />

          <TextBox
            endLabel="Rounds"
            value={rounds}
            onChange={(e) => {
              const newRounds = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );
              setRounds(newRounds);
            }}
            onBlur={(e) => {
              if (!e.target.value.replace(/[^0-9]/g, '')) {
                setRounds(1);
              }
            }}
          />

          <Button onClick={startCoundown}>Start</Button>
        </>
      )}

      {state.isCountdown && <Countdown onExpire={startClock} />}

      {state.isStart && (
        <>
          <Digits minutes={currentMinutes} seconds={currentSeconds} />
          <Rounds rounds={rounds} currentRound={currentRound} />

          <Button onClick={isRunning ? pause : resume}>
            {isRunning ? 'Pause' : 'Resume'}
          </Button>
        </>
      )}
    </PageContainer>
  );
};

export default TabataPage;
