import { useTime } from 'react-timer-hook';

import { Digits } from '../componentes/Digits';
import { PageContainer } from './PageContainer';

const ClockPage: React.FC = () => {
  const { seconds, hours, minutes } = useTime({});

  return (
    <PageContainer>
      <Digits showHours hours={hours} minutes={minutes} seconds={seconds} />
    </PageContainer>
  );
};

export default ClockPage;
