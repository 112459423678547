import HomeIcon from '@mui/icons-material/Home';
import { IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { ROUTE_PATHS } from '../routing/route-paths';

export const ButtonHome: React.FC = () => {
  return (
    <IconButton
      edge="start"
      color="inherit"
      aria-label="home"
      component={RouterLink}
      to={ROUTE_PATHS.HOME}
      sx={{ mx: 0.5 }}
    >
      <HomeIcon />
    </IconButton>
  );
};
