import FullScreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExit';
import { IconButton } from '@mui/material';

import { ContextFullScreen } from '../context/Fullscreen';

export const ButtonFullScreen: React.FC = () => {
  return (
    <ContextFullScreen.Consumer>
      {(handle) => (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="fullscreen"
          onClick={() => {
            if (!handle) {
              return;
            }

            if (handle.active) {
              handle.exit();
            } else {
              handle.enter();
            }
          }}
          sx={{ m: 0 }}
        >
          {handle?.active ? <FullScreenExitIcon /> : <FullScreenIcon />}
        </IconButton>
      )}
    </ContextFullScreen.Consumer>
  );
};
