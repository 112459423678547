import { Typography, TypographyProps } from '@mui/material';

type IProps = TypographyProps & {
  currentRound: number;
  rounds: number;
};

export const Rounds: React.FC<IProps> = ({
  currentRound = 0,
  rounds = 0,
}: IProps) => {
  return (
    <Typography variant="h5">
      Round {currentRound > rounds ? rounds : currentRound} / {rounds}
    </Typography>
  );
};
