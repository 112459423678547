import { useReducer, useState } from 'react';

import { useTimer } from 'react-timer-hook';

import { Button } from '../componentes/Button';
import { Countdown } from '../componentes/Countdown';
import { Digits } from '../componentes/Digits';
import { Rounds } from '../componentes/Rounds';
import { TextBox } from '../componentes/TextBox';
import { calcTotalSeconds } from '../utils/time';
import { useSounds } from '../utils/use-sounds';
import { PageContainer } from './PageContainer';

const BEEP_OFFSET = 5;

type State = {
  isSetup: boolean;
  isCountdown: boolean;
  isStart: boolean;
};

enum ActionType {
  SET_COUNTDOWN,
  SET_SETUP,
  SET_START,
}

const initialState = {
  isCountdown: false,
  isSetup: true,
  isStart: false,
};

function reducer(state: State, action: ActionType) {
  switch (action) {
    case ActionType.SET_COUNTDOWN:
      return {
        isCountdown: true,
        isSetup: false,
        isStart: false,
      };
    case ActionType.SET_SETUP:
      return {
        isCountdown: false,
        isSetup: true,
        isStart: false,
      };
    case ActionType.SET_START: {
      return {
        isCountdown: false,
        isSetup: false,
        isStart: true,
      };
    }
    default:
      return state;
  }
}

const EmomPage: React.FC = () => {
  const { playMid, playFinal } = useSounds();
  const [state, dispatch] = useReducer(reducer, initialState);

  const [intervalMinutes, setIntervalMinutes] = useState(1);
  const [intervalSeconds, setIntervalSeconds] = useState(0);
  const [intervalTotalSeconds, setIntervalTotalSeconds] = useState(0);
  const [rounds, setRounds] = useState(10);
  const { seconds, hours, minutes, isRunning, pause, resume, restart } =
    useTimer({
      expiryTimestamp: new Date(),
      onExpire: () => dispatch(ActionType.SET_SETUP),
    });

  const startClock = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + rounds * intervalTotalSeconds);
    restart(time);
    dispatch(ActionType.SET_START);
  };

  const startCoundown = () => {
    const totalSeconds = calcTotalSeconds(
      intervalSeconds * rounds,
      intervalMinutes * rounds
    );
    setIntervalTotalSeconds(totalSeconds / rounds);
    dispatch(ActionType.SET_COUNTDOWN);
  };

  let currentSeconds = 0,
    currentMinutes = 0,
    currentRound = 0;
  if (state.isStart && isRunning) {
    const secondsLeft = calcTotalSeconds(seconds, minutes, hours) - 1;
    const secondsLeftInRound =
      Math.ceil(secondsLeft % intervalTotalSeconds) + 1;

    currentSeconds = secondsLeftInRound % 60;

    currentMinutes = Math.floor(secondsLeftInRound / 60);
    currentRound = rounds - Math.floor(secondsLeft / intervalTotalSeconds);

    if (secondsLeftInRound === intervalTotalSeconds || secondsLeft < 0) {
      playFinal();
    } else if (secondsLeftInRound <= BEEP_OFFSET) {
      playMid();
    }
  }

  return (
    <PageContainer>
      {state.isSetup && (
        <>
          <TextBox
            endLabel="Minutes"
            onChange={(e) => {
              const newMinutes = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );
              setIntervalMinutes(newMinutes);
            }}
            value={intervalMinutes}
          />

          <TextBox
            endLabel="Seconds"
            onChange={(e) => {
              const newSeconds = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );

              setIntervalSeconds(newSeconds < 60 ? newSeconds : 59);
            }}
            onBlur={(e) => {
              if (!e.target.value.replace(/[^0-9]/g, '')) {
                setIntervalSeconds(0);
              }
            }}
            value={intervalSeconds}
          />

          <TextBox
            endLabel="Rounds"
            value={rounds}
            onChange={(e) => {
              const newRounds = parseInt(
                e.target.value.replace(/[^0-9]/g, '') || '0'
              );
              setRounds(newRounds);
            }}
            onBlur={(e) => {
              if (!e.target.value.replace(/[^0-9]/g, '')) {
                setRounds(1);
              }
            }}
          />

          <Button onClick={startCoundown}>Start</Button>
        </>
      )}

      {state.isCountdown && <Countdown onExpire={startClock} />}

      {state.isStart && (
        <>
          <Digits minutes={currentMinutes} seconds={currentSeconds} />
          <Rounds rounds={rounds} currentRound={currentRound} />

          <Button onClick={isRunning ? pause : resume}>
            {isRunning ? 'Pause' : 'Resume'}
          </Button>
        </>
      )}
    </PageContainer>
  );
};

export default EmomPage;
