import { AppBar, Box, Toolbar } from '@mui/material';
import { styled } from '@mui/system';

import { ButtonFullScreen } from './ButtonFullScreen';

const StyledFooter = styled(Box)(() => ({
  bottom: 0,
  left: 0,
  position: 'absolute',
  width: '100%',
}));

export const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <AppBar position="relative" color="transparent">
        <Toolbar>
          <Box sx={{ flex: 1 }} />
          <ButtonFullScreen />
          <Box sx={{ flex: 1 }} />
        </Toolbar>
      </AppBar>
    </StyledFooter>
  );
};
