import { Typography } from '@mui/material';
import { useTimer } from 'react-timer-hook';

import { Digits } from '../componentes/Digits';
import { useSounds } from '../utils/use-sounds';

const COUNTDOWN_TIME_SECONDS = 5;
const LABEL_TIME_SECONDS = 2;

interface IProps {
  onExpire?: () => void;
}

export const Countdown: React.FC<IProps> = ({
  onExpire = () => null,
}: IProps) => {
  const { playMid } = useSounds();
  const { seconds, hours, minutes } = useTimer({
    expiryTimestamp: (() => {
      const time = new Date();
      time.setSeconds(
        time.getSeconds() + COUNTDOWN_TIME_SECONDS + LABEL_TIME_SECONDS
      );
      return time;
    })(),
    onExpire,
  });

  const showCountdown = seconds <= COUNTDOWN_TIME_SECONDS;

  if (seconds > 0 && showCountdown) {
    playMid();
  }

  return seconds <= COUNTDOWN_TIME_SECONDS ? (
    <Digits hours={hours} minutes={minutes} seconds={seconds} />
  ) : (
    <Typography variant="h4">Get Ready!</Typography>
  );
};
