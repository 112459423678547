import useSound from 'use-sound';

import beepFinal from '../sounds/beep-final.mp3';
import beepMid from '../sounds/beep-mid.mp3';

export const useSounds = () => {
  const [playMid] = useSound(beepMid);
  const [playFinal] = useSound(beepFinal);

  return {
    playFinal,
    playMid,
  };
};
