import React, { Suspense } from 'react';

import { Navigate, Route, Routes as Switch } from 'react-router-dom';

// pages
import AmrapPage from '../pages/Amrap';
import ClockPage from '../pages/Clock';
import EmomPage from '../pages/Emom';
import HomePage from '../pages/Home';
import TabataPage from '../pages/Tabata';
import TimePage from '../pages/Time';
import { ROUTE_PATHS } from './route-paths';

export const Routes: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route path={ROUTE_PATHS.HOME} element={<HomePage />} />
        <Route path={ROUTE_PATHS.AMRAP} element={<AmrapPage />} />
        <Route path={ROUTE_PATHS.CLOCK} element={<ClockPage />} />
        <Route path={ROUTE_PATHS.EMOM} element={<EmomPage />} />
        <Route path={ROUTE_PATHS.TABATA} element={<TabataPage />} />
        <Route path={ROUTE_PATHS.TIME} element={<TimePage />} />
        <Route path="*" element={<Navigate to={ROUTE_PATHS.HOME} />} />
      </Switch>
    </Suspense>
  );
};
