import { Link } from 'react-router-dom';

import { Button } from '../componentes/Button';
import { ROUTE_PATHS } from '../routing/route-paths';
import { PageContainer } from './PageContainer';

const HomePage: React.FC = () => {
  return (
    <PageContainer>
      <Button component={Link} to={ROUTE_PATHS.AMRAP}>
        AMRAP
      </Button>
      <Button component={Link} to={ROUTE_PATHS.EMOM}>
        EMOM
      </Button>
      <Button component={Link} to={ROUTE_PATHS.TIME}>
        FOR TIME
      </Button>
      <Button component={Link} to={ROUTE_PATHS.TABATA}>
        TABATA
      </Button>
      <Button component={Link} to={ROUTE_PATHS.CLOCK}>
        CLOCK
      </Button>
    </PageContainer>
  );
};

export default HomePage;
