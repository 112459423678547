import { CssBaseline, ThemeProvider } from '@mui/material';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { BrowserRouter } from 'react-router-dom';

import { ContextFullScreen } from './context/Fullscreen';
import { Routes } from './routing/Routes';
import { theme } from './theme';

function App() {
  const handle = useFullScreenHandle();

  return (
    <ThemeProvider theme={theme}>
      <FullScreen handle={handle}>
        <ContextFullScreen.Provider value={handle}>
          <CssBaseline />
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ContextFullScreen.Provider>
      </FullScreen>
    </ThemeProvider>
  );
}

export default App;
