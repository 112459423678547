import { Button as MuiButton } from '@mui/material';

export const Button: React.FC<any> = (props) => {
  return (
    <MuiButton
      variant="outlined"
      size="large"
      fullWidth
      style={{ marginBottom: 10, marginTop: 10 }}
      {...props}
    />
  );
};
