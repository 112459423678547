import { Typography, TypographyProps } from '@mui/material';

type IProps = TypographyProps & {
  showHours?: boolean;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export const Digits: React.FC<IProps> = ({
  showHours,
  seconds = 0,
  minutes = 0,
  hours = 0,
  ...restProps
}: IProps) => {
  const clockHours = `${
    showHours ? hours.toString().padStart(2, '0') + ':' : ''
  }`;
  const clockMinutes = `${(showHours ? minutes : minutes + hours * 60)
    .toString()
    .padStart(2, '0')}`;
  const clockSeconds = seconds.toString().padStart(2, '0');

  return (
    <Typography variant="h2" {...restProps}>
      {`${clockHours}${clockMinutes}:${clockSeconds}`}
    </Typography>
  );
};
